import { produce } from "immer";
import { isEmpty } from "lodash";
import cloneDeep from "lodash.clonedeep";
import mapValues from "lodash.mapvalues";
import { nanoid } from "nanoid";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
  setFooterBoxesAction,
  setHeaderBoxesAction,
  setLayoutAndBoxesAction,
} from "../action/action";
import {
  IPageLayout,
  ISectionPageBuilder,
  PageWithBoxesPageBuilder,
  TPlatform,
} from "../dto/page/pageBuilder.types";
import {
  IPaginatedGridResponse,
  IPaginationGridPayload,
} from "../dto/paginationResponse.types";
import {
  conversionMapContentAndSettings,
  convertPageWithBoxes,
  nanoidNumber,
} from "../helpers";
import { API, api } from "./api";

export type QueryReturnValue<T = unknown, E = unknown, M = unknown> =
  | {
      error: E;
      data: undefined;
      meta?: M;
    }
  | {
      error: undefined;
      data: T;
      meta?: M;
    };

type QueryLayout = QueryReturnValue<
  PageWithBoxesPageBuilder | undefined,
  FetchBaseQueryError | undefined,
  Record<string, unknown>
>;

const addSection = (data: PageWithBoxesPageBuilder) => {
  if (data === undefined) return;
  const isPageEmpty = data.page.sections.length === 0;

  if (isPageEmpty) {
    const dataCopy = cloneDeep(data);

    dataCopy.page = {
      ...dataCopy.page,
      sections: [
        {
          id: -nanoidNumber(),
          customCss: "",
          uuid: nanoid(),
          name: "ROW",
          sectionOrder: 0,
          version: 0,
          mobileSubSection: false,
          slots: [
            {
              columns: 12,
              customCss: "",
              displaySettings: {},
              mobileDisplaySettings: {},
              groupingMode: "NONE",
              id: -nanoidNumber(),
              name: "COLUMN",
              sections: [],
              stacks: [],
            },
          ],
          displaySettings: {},
          mobileDisplaySettings: {},
        },
      ],
    };

    return dataCopy;
  }

  return data;
};

const transformPageBody = (body: PageWithBoxesPageBuilder) => {
  const filterEmptySections = (sections: ISectionPageBuilder[]) => {
    return sections.filter((section) => section.slots.length !== 0);
  };

  const recursivelyMapSection = (section: ISectionPageBuilder): unknown => {
    const removeId = (id: number | undefined) => {
      return id && id < 0 ? undefined : id;
    };

    return {
      ...section,
      id: removeId(section.id),
      slots: section.slots.map((slot) => ({
        ...slot,
        sections: slot.sections.map(recursivelyMapSection),
        id: removeId(slot.id),
        stacks: slot.stacks.map((stack) => ({
          ...stack,
          publications: stack.publications.map((publication) => ({
            ...publication,
            id: removeId(publication.id),
            publicationConditions: publication.publicationConditions.map(
              (condition) => ({
                ...condition,
                type: condition.type + "Request",
              })
            ),
          })),
        })),
      })),
    };
  };

  const cleanBoxFromEmptyFields = (boxes: Record<string, any>) =>
    mapValues(boxes, (box) => {
      return {
        ...mapValues(box, (value) =>
          typeof value === "object" && isEmpty(value) ? null : value
        ),
        settings: box?.settings,
        messages: box?.messages,
        mobileSettings: box?.mobileSettings,
      };
    });

  return {
    page: {
      ...body.page,
      sections: filterEmptySections(body.page.sections).map(
        recursivelyMapSection
      ),
    },
    boxes: {
      ...cleanBoxFromEmptyFields(body.boxes),
    },
  };
};

export const pages = api
  .enhanceEndpoints({ addTagTypes: ["DRAFT_PAGE"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPageWizard: builder.query<
        PageWithBoxesPageBuilder,
        { pageId?: string | number }
      >({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryFn: async (arg, { dispatch }, _extraOptions, baseQuery) => {
          const { pageId } = arg;
          let page: QueryLayout = await baseQuery(
            API.getPageWizard.endpoint(pageId)
          );

          if (!page?.data) {
            return page;
          }

          const footerId = page.data?.page?.footerId;
          const headerId = page.data?.page?.headerId;

          if (typeof footerId === "number") {
            const footer = await dispatch(
              pages.endpoints.getHeaderFooter.initiate(
                { pageId: footerId },
                { forceRefetch: true }
              )
            );
            const convertedBoxes = conversionMapContentAndSettings(
              footer.data.boxes
            );
            page = produce(page, (pageCopy: QueryLayout) => {
              if (pageCopy.data) {
                pageCopy.data.page.footer = footer.data.page;
              }
            });
            dispatch(setFooterBoxesAction(convertedBoxes));
          }

          if (typeof headerId === "number") {
            const header = await dispatch(
              pages.endpoints.getHeaderFooter.initiate(
                {
                  pageId: headerId,
                },
                { forceRefetch: true }
              )
            );
            const convertedBoxes = conversionMapContentAndSettings(
              header.data.boxes
            );
            page = produce(page, (pageCopy: QueryLayout) => {
              if (pageCopy.data) {
                pageCopy.data.page.header = header.data.page;
              }
            });
            dispatch(setHeaderBoxesAction(convertedBoxes));
          }

          if (page.data) {
            // addSection - temporaty for empty page
            const data = addSection(page.data);

            if (data) {
              const convertedPageWithBoxes = convertPageWithBoxes(data);
              dispatch(setLayoutAndBoxesAction(convertedPageWithBoxes));

              return { data };
            }
          }
          return { data: page.data };
        },
        providesTags: ["DRAFT_PAGE"],
        keepUnusedDataFor: 0,
      }),

      getPagePortal: builder.query<
        PageWithBoxesPageBuilder | undefined,
        { pageQuery: string; platform: TPlatform }
      >({
        queryFn: async ({ pageQuery, platform }, _0, _1, baseQuery) => {
          const mainPage = await baseQuery(
            API.getPagePortal.endpoint(`?platform=${platform}&${pageQuery}`)
          );

          if (!mainPage.data) {
            return mainPage;
          }
          const footerId = mainPage.data?.page?.footerId;
          const headerId = mainPage.data?.page?.headerId;

          const [footer, header] = await Promise.all([
            footerId &&
              baseQuery(
                API.getPagePortal.endpoint(`/${footerId}?platform=${platform}`)
              ),
            headerId &&
              baseQuery(
                API.getPagePortal.endpoint(`/${headerId}?platform=${platform}`)
              ),
          ]);

          return {
            data: {
              page: {
                ...mainPage.data.page,
                header: header?.data.page || null,
                footer: footer?.data.page || null,
              },
              boxes: mainPage.data.boxes,
              headerBoxes: header?.data.boxes || {},
              footerBoxes: footer?.data.boxes || {},
            },
          };
        },
      }),
      getHeaderFooter: builder.query({
        // @ts-expect-error: TODO queryFn types
        queryFn: async (arg, _, _extraOptions, baseQuery) => {
          const { pageId } = arg;
          const page: QueryLayout = await baseQuery(
            API.getPageWizard.endpoint(pageId)
          );

          if (!page?.data) {
            // TODO: we should return page for error handling. however, this requires handling in getWizardPage
            return { error: "No page data" };
          }

          return { data: page.data };
        },
      }),
      postPages: builder.mutation<
        IPaginatedGridResponse<IPageLayout[]>,
        Partial<IPaginationGridPayload>
      >({
        query: (body: IPaginationGridPayload) => {
          return {
            url: API.postPages.endpoint(),
            method: "POST",
            body,
          };
        },
      }),
      updatePage: builder.mutation<
        {
          pageId: number;
          body: PageWithBoxesPageBuilder;
        },
        Partial<{
          pageId: number;
          body: PageWithBoxesPageBuilder;
        }>
      >({
        query: ({
          pageId,
          body,
        }: {
          pageId: number;
          body: PageWithBoxesPageBuilder;
        }) => {
          return {
            url: API.putPage.endpoint(pageId),
            method: "PUT",
            body: transformPageBody(body),
            headers: { "Content-Type": "application/json" },
          };
        },
        invalidatesTags: ["DRAFT_PAGE"],
      }),
      getPageUrlById: builder.query<
        { url: string; draft: boolean },
        string | number
      >({
        query: (arg) => {
          return {
            url: API.getPageUrlById.endpoint(arg),
          };
        },
      }),
    }),
  });

// Export hooks for usage in functional components
export const {
  useGetPageWizardQuery,
  useGetPagePortalQuery,
  useUpdatePageMutation,
  usePostPagesMutation,
  useLazyGetPageUrlByIdQuery,
  util: { getRunningQueriesThunk },
} = pages;

// export endpoints for use in SSR
export const {
  getPageWizard,
  postPages,
  getHeaderFooter,
  getPagePortal,
  getPageUrlById,
} = pages.endpoints;
