import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import VideoIcon from "../../global/components/VideoIcon/VideoIcon";
import { getImageNameFromUrl } from "../../helpers/helpers";
import { ratioToNumber } from "../../settingsPatterns/boxes/BoxVideo/StyledVideo";
import { RatioOption } from "../../settingsPatterns/boxes/BoxVideo/StyledVideo.types";
import { ratioStyle } from "../../settingsPatterns/composeCss";

export const renderCoverImageOrIcon = (
  coverImage: string,
  imageWidth: number | undefined,
  seoDescription: string,
  ratio: RatioOption,
  orientation: string
) => {
  if (coverImage.length > 0 && imageWidth) {
    return (
      <ImageWrapper
        imageUrl={coverImage}
        seoDescription={seoDescription}
        imageFit
        imageAlignment={{
          horizontal: AlignmentHorizontalOption.CENTER,
          vertical: AlignmentVerticalOption.CENTER,
        }}
        opacity={100}
        width={imageWidth}
        height={imageWidth / ratioToNumber(ratioStyle(ratio, orientation))}
      />
    );
  } else {
    return (
      <div className="video-container__video-icon">
        <VideoIcon width="160" height="100" viewBox="0 0 60 50" />
      </div>
    );
  }
};

export const getCoverImgAltText = (
  coverImage: string,
  seoDescription: string
): string => seoDescription || getImageNameFromUrl(coverImage);

export const handleFullscreen = (
  containerRef: React.RefObject<HTMLDivElement>
) => {
  if (screenfull.isEnabled && containerRef.current) {
    screenfull.toggle(containerRef.current as Element);
  }
};

export const handleFastForward = (playerRef: React.RefObject<ReactPlayer>) => {
  if (playerRef.current) {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  }
};

export const handleRewind = (playerRef: React.RefObject<ReactPlayer>) => {
  if (playerRef.current) {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  }
};

export const handleProgressBarClick = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  playerRef: React.RefObject<ReactPlayer>
) => {
  if (playerRef.current) {
    const progressBar = event.currentTarget;
    const progressBarWidth = progressBar.clientWidth;
    const clickX = event.clientX - progressBar.getBoundingClientRect().left;
    const newProgress = clickX / progressBarWidth;
    playerRef.current.seekTo(newProgress * playerRef.current.getDuration());
  }
};

export const PLAYBACK_RATE_OPTIONS = [
  0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0,
];

export const handleVolumeChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setVolume: React.Dispatch<React.SetStateAction<number>>
) => {
  const newVolume = parseFloat(e.target.value) / 100;
  setVolume(newVolume);
};

export const convertToRatioFormat = (option: RatioOption | string): string => {
  switch (option) {
    case "ONE_TO_ONE":
      return "1:1";
    case "THREE_TO_ONE":
      return "3:1";
    case "THREE_TO_TWO":
      return "3:2";
    case "FOUR_TO_THREE":
      return "4:3";
    case "FIVE_TO_THREE":
      return "5:3";
    case "FIVE_TO_FOUR":
      return "5:4";
    case "SIXTEEN_TO_NINE":
      return "16:9";
    case "SIXTEEN_TO_TEN":
      return "16:10";
    case "TWENTY_ONE_TO_NINE":
      return "21:9";
    default:
      return option;
  }
};
