import {
  IProductAttributePm,
  IProductListAttributesResponse,
  IProductListAttributesTypeResponse,
  IProductListCategoryTreeResponse,
  IProductListPoolsResponse,
  IProductsResponse,
  PIMAttributeTypes,
  PIMInputTypes,
} from "../../dto/productList.types";

export const PRODUCTS_MOCK: IProductsResponse = {
  result: [
    {
      badges: { 3: "BADGE 1" },
      basePrice: 81.06,
      createdBy: undefined,
      creationDate: undefined,
      deliveryTime: undefined,
      enabled: true,
      family: "test",
      id: "1",
      image:
        "https://dev-minio-ecp.nd0.pl/poc1-pim-dev/0/7/6/9/0769c5ad8a6a5a9e554b0f29560e3d9d130e3811_PL_654321_1.jpg",

      modificationDate: "2022-10-04T13:21:23.933135Z",
      modifiedBy: "System",
      photoGallery: [
        { order: 1, url: "/static/images/placeholder.png" },
        { order: 2, url: "/static/images/placeholder.png" },
        { order: 3, url: "/static/images/placeholder.png" },
        { order: 4, url: "/static/images/placeholder.png" },
        { order: 5, url: "/static/images/placeholder.png" },
        { order: 6, url: "/static/images/placeholder.png" },
      ],
      pools: { 15: "test" },
      productName: "test",
      sku: "1",
      stock: 1,
      visibility: true,
    },
    {
      badges: { 1: "BADGE 2" },
      basePrice: 81.06,
      createdBy: undefined,
      creationDate: undefined,
      deliveryTime: undefined,
      enabled: true,
      family: "test",
      id: "2",
      image: "/asd",
      modificationDate: "2022-10-04T13:21:23.933135Z",
      modifiedBy: "System",
      photoGallery: [{ order: 2, url: "/static/images/placeholder.png" }],
      pools: { 15: "test2" },
      productName: "test2",
      sku: "2",
      stock: 1,
      visibility: true,
    },
    {
      badges: { 1: "BADGE 1" },
      basePrice: 81.06,
      createdBy: undefined,
      creationDate: undefined,
      deliveryTime: undefined,
      enabled: true,
      family: "test",
      id: "3",
      image: undefined,
      modificationDate: "2022-10-04T13:21:23.933135Z",
      modifiedBy: "System",
      photoGallery: [{ order: 3, url: "/static/images/placeholder.png" }],
      pools: { 15: "test3" },
      productName: "test3",
      sku: "3",
      stock: 1,
      visibility: true,
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 1,
    total: 1,
  },
};

export const PRODUCT_LIST_PRODUCT_PROJECTION = [
  {
    attributeId: "alcoholConcession",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "alternativeUnitOfMeasures",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "associations",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "axis",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "badges",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "basePrice",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "Brand",
    type: "pim_catalog_simpleselect",
    options: [
      {
        code: "Belutti",
        attribute: "Brand",
        sortOrder: 3,
        label: "Belutti z długą nazwą do weryfikacji i do sprawdzenia",
      },
      {
        code: "Brand",
        attribute: "Brand",
        sortOrder: 4,
        label: "Marka",
      },
      {
        code: "Lux",
        attribute: "Brand",
        sortOrder: 0,
        label: "Lux",
      },
      {
        code: "super_lux",
        attribute: "Brand",
        sortOrder: 15,
        label: "Super Lux",
      },
    ],
  },
  {
    attributeId: "conceptId",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "concession",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "coverPhoto",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "currency",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "deliveryQuantity",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "deliveryTime",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "description",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "grossPrice",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "grossPrice",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "Height",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "hoverPhoto",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "Image",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Length",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "lowestCategoryLevel",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "mainUnitOfMeasurement",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "mainUnitOfMeasurementLabel",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "MaterialID",
    type: "pim_catalog_text",
    options: null,
  },
  {
    attributeId: "medicalConcession",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "Net_price",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "netPrice",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "netPrice",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "omnibusPrice",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "photoGallery",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "Picture1",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture10",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture2",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture3",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture4",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture5",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture6",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture7",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture8",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "Picture9",
    type: "pim_catalog_image",
    options: null,
  },
  {
    attributeId: "pools",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "ProductName",
    type: "pim_catalog_text",
    options: null,
  },
  {
    attributeId: "promotionPrice",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "promotionPrice",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "rating",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "roundIndicator",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "sku",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "Stock",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "suggestedPrice",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "TAX",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "tobaccoConcession",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "url",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "variants",
    type: "srp_attribute",
    options: [],
  },
  {
    attributeId: "Weight",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "Width",
    type: "pim_catalog_number",
    options: null,
  },
  {
    attributeId: "wishlist",
    type: "srp_attribute",
    options: [],
  },
];

export const PRODUCT_LIST_POOLS_MOCK: IProductListPoolsResponse = {
  result: [
    {
      id: 1,
      version: 2,
      name: "Pool Test",
      badgeKind: undefined,
      modifiedBy: "ANONYMOUS",
      modificationDate: new Date("2022-07-14T08:43:40.267138Z"),
      createdBy: "ANONYMOUS",
      creationDate: new Date("2022-07-07T09:22:01.97469Z"),
      productCount: 1,
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 1,
    total: 1,
  },
};

export const PRODUCT_LIST_ATTRIBUTES_TYPE_MOCK: IProductListAttributesTypeResponse =
{
  pim_catalog_date: {
    fieldType: "TIMESTAMP",
    path: "data",
    control: "DATE" as PIMAttributeTypes,
  },
  pim_catalog_file: {
    fieldType: "STRING",
    path: "data",
    control: "TEXT" as PIMAttributeTypes,
  },
  pim_catalog_image: {
    fieldType: "STRING",
    path: "data",
    control: "TEXT" as PIMAttributeTypes,
  },
  pim_catalog_textarea: {
    fieldType: "STRING",
    path: "data",
    control: "TEXT" as PIMAttributeTypes,
  },
  pim_catalog_price_collection: {
    fieldType: "DOUBLE",
    path: "data.amount",
    control: "NUMBER" as PIMAttributeTypes,
  },
  pim_catalog_boolean: {
    fieldType: "BOOLEAN",
    path: "data",
    control: "BOOLEAN" as PIMAttributeTypes,
  },
  pim_catalog_text: {
    fieldType: "STRING",
    path: "data",
    control: "TEXT" as PIMAttributeTypes,
  },
  pim_catalog_number: {
    fieldType: "DOUBLE",
    path: "data",
    control: "NUMBER" as PIMAttributeTypes,
  },
  pim_catalog_multiselect: {
    fieldType: "STRING",
    path: "data",
    control: "MULTISELECT" as PIMAttributeTypes,
  },
  pim_catalog_simpleselect: {
    fieldType: "STRING",
    path: "data",
    control: "SELECT" as PIMAttributeTypes,
  },
  pim_catalog_identifier: {
    fieldType: "STRING",
    path: "data",
    control: "TEXT" as PIMAttributeTypes,
  },
  pim_catalog_metric: {
    fieldType: "DOUBLE",
    path: "data.amount",
    control: "NUMBER" as PIMAttributeTypes,
  },
};

export const PRODUCT_LIST_ATTRIBUTES_MOCK: IProductListAttributesResponse = {
  result: [
    {
      id: "Boolean Attribute",
      code: "Test",
      filterable: true,
      type: PIMInputTypes.pim_catalog_boolean,
      label: "Boolean Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: undefined,
      name: "Test",
    },
    {
      id: "Test2",
      code: "Test2",
      filterable: true,
      type: PIMInputTypes.pim_catalog_text,
      label: "Text Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: undefined,
      name: "Test2",
    },
    {
      id: "Test3",
      code: "Test3",
      filterable: true,
      type: PIMInputTypes.pim_catalog_number,
      label: "Number Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      sortable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: undefined,
      name: "Test3",
    },
    {
      id: "Test4",
      code: "Test4",
      filterable: true,
      type: PIMInputTypes.pim_catalog_simpleselect,
      label: "Simpleselect Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: [
        {
          code: "OHU1",
          attribute: "Test4",
          sortOrder: null,
          label: "OHU",
        },
        {
          code: "OPL2",
          attribute: "Test4",
          sortOrder: null,
          label: "OPL",
        },
      ],
      name: "Test4",
    },
    {
      id: "Test5",
      code: "Test5",
      filterable: true,
      type: PIMInputTypes.pim_catalog_multiselect,
      label: "Multiselect Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: [
        {
          code: "OHU1",
          attribute: "Test5",
          sortOrder: null,
          label: "OHU",
        },
        {
          code: "OPL2",
          attribute: "Test5",
          sortOrder: null,
          label: "OPL",
        },
      ],
      name: "Test5",
    },
    {
      id: "Test6",
      code: "Test6",
      filterable: true,
      type: PIMInputTypes.pim_catalog_date,
      label: "Test6",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: undefined,
      name: "Test6",
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 6,
    total: 6,
  },
};

export const PRODUCT_LIST_CATEGORY_TREE_MOCK: {
  children: IProductListCategoryTreeResponse;
} = {
  children: [
    {
      label: "TEST",
      code: "01",
      updated: "2022-05-20T13:54:34+00:00",
      parentCode: "",
      children: [
        {
          label: "ChildrenTestCategory",
          code: "0101",
          updated: "2022-05-20T13:54:34+00:00",
          parentCode: "",
          children: [],
        },
      ],
    },
  ],
};
