import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { IImageWrapperProps } from "./ImageWrapper.types";

export const imgResizer = (imgUrl: string | TUrl, width?: number): TUrl => {
  if (imgUrl.endsWith(".svg") || !process.env["NEXT_PUBLIC_RESIZER_URL"])
    return imgUrl as TUrl;

  const resizeUrl = `${process.env["NEXT_PUBLIC_RESIZER_URL"]}${
    width !== undefined ? `${Math.max(Math.round(width), 1080)}x` : ""
  }/${imgUrl}`;

  return resizeUrl;
};

//TODO: deprecated, try to use useObserveElementDimensions when you are around this hook
// function not rerender when parent size is changed
export const useImageSize = (): [
  RefObject<HTMLDivElement>,
  number | undefined,
  number | undefined
] => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [imageDimensions, setImageDimensions] = useState<{
    imageWidth: number | undefined;
    imageHeight: number | undefined;
  }>({
    imageWidth: undefined,
    imageHeight: undefined,
  });

  useEffect(() => {
    const newWidth = parentRef.current?.getBoundingClientRect().width;
    const newHeight = parentRef.current?.getBoundingClientRect().height;

    if (typeof newWidth === "number" && typeof newHeight === "number") {
      setImageDimensions({
        imageWidth: newWidth,
        imageHeight: newHeight,
      });
    }
  }, [
    parentRef,
    parentRef.current?.offsetWidth,
    parentRef.current?.offsetHeight,
  ]);

  return [parentRef, imageDimensions.imageWidth, imageDimensions.imageHeight];
};

//try this function instead of useImageSize
export const useObserveElementDimensions = <
  T extends HTMLElement = HTMLDivElement
>(deafult?: {
  width: number;
  height: number;
}): [RefObject<T>, number, number] => {
  const parentRef = useRef<T>(null);

  const [imageDimensions, setImageDimensions] = useState<{
    imageWidth: number | undefined;
    imageHeight: number | undefined;
  }>({
    imageWidth: undefined,
    imageHeight: undefined,
  });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setImageDimensions({
        imageWidth: entries[0].contentRect.width,
        imageHeight: entries[0].contentRect.height,
      });
    });

    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      parentRef.current && observer.unobserve(parentRef.current);
    };
  }, []);

  return [
    parentRef,
    imageDimensions.imageWidth ?? deafult?.width ?? 0,
    imageDimensions.imageHeight ?? deafult?.height ?? 0,
  ];
};

export interface Size {
  width: number;
  height: number;
}
/**
 * `useElementSize` is a hook to measure the size of a DOM element.
 * It tracks the width and height of the element and updates them on window resize or element changes.
 *
 * @param ref - The React ref object attached to the element to measure.
 * @return - An object containing the `width` and `height` of the element.
 */

export function useElementSize(ref: RefObject<HTMLElement>): [
  number,
  number
] {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const handleSize = useCallback(() => {
    if (ref.current) {
      setSize({
        width: ref.current?.offsetWidth ?? 0,
        height: ref.current?.offsetHeight ?? 0,
      });
    }
  }, [ref.current]);

  const useEnviromentEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;

  useEnviromentEffect(() => {
    if (!ref.current) return;

    handleSize();

    const resizeObserver = new ResizeObserver(handleSize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, [ref, handleSize]);

  return [size.width, size.height];
}

export const checkImageType = (
  imgSettings: Pick<
    IImageWrapperProps,
    "width" | "height" | "parallax" | "imageUrl"
  >
) => {
  const { imageUrl, width, height, parallax } = imgSettings;
  if (!imageUrl) return "none";
  if (width && height) return "next-resize";
  if (parallax) return "backgorund-img";
  if (width) return "ecp-resize";
  return "none";
};
