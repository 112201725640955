import { z } from "zod";
import { GridPaginationOptions } from "../article.type";
import {
  BackgroundScheme,
  Border_v2Scheme,
  Border_v3Scheme,
  IIconSchema,
} from "./themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IGlobalColorStyles,
  ISpacing,
  IThemeState,
  OutlineOptions,
  TButtonReadoutValue,
  TColorReadoutValue,
  TInputReadoutValue,
  TLinkReadoutValue,
  TTypoReadoutValue,
} from "./themeSettings.types";

export enum ImagePlacement {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

export enum BannerSizeOptions {
  AUTO = "auto",
  SMALL = "350",
  MEDIUM = "500",
  LARGE = "650",
  CUSTOM = "CUSTOM",
}

export enum ImageInlineWidthOptions {
  ONE_THIRD = "33.33%",
  HALF = "50%",
  FULL = "100%",
}

export enum HoverEffects {
  NONE = "NONE",
  ZOOMIN = "ZOOMIN",
  ZOOMOUT = "ZOOMOUT",
  OPACITY = "OPACITY",
  BLUR = "BLUR",
  GREY = "GREY",
  FLASHING = "FLASHING",
}

export enum ProductHoverEffects {
  SECOND = "SECOND",
}

export enum BannersPositions {
  FIX = "FIX",
  SHAKE = "SHAKE",
}

export enum CountOfTemplatesInShake {
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
}

export interface IUsePrepareSettingsProps<T, S> {
  props: T;
  settingsPattern: S;
  options?: Partial<{
    includeTheme: boolean;
    themePath: string;
    themeWrapperPath: string;
  }>;
}

/**
 * Keys prefixed by a dollar sign to make them only accessible by styled-components
 *
 * @param T -
 * defines settings
 */
export interface IBoxBaseSettings<T> extends Record<string, unknown> {
  $settings: T;
  theme: IThemeState;
}

export interface IAlignment {
  horizontal: AlignmentHorizontalOption;
  vertical: AlignmentVerticalOption;
}

export interface IBorder {
  color: TColorReadoutValue;
  width: number;
  style: OutlineOptions;
  radius: number;
}

export type IBorder_v2 = z.infer<typeof Border_v2Scheme>;

export type IBorder_v3 = z.infer<typeof Border_v3Scheme>;

export type IIcon = z.infer<typeof IIconSchema>;

export type IBackground = z.infer<typeof BackgroundScheme>;

export interface IElementVisibility {
  show: boolean;
}

export interface IElementText {
  font: TTypoReadoutValue;
  text: Pick<IGlobalColorStyles, "color">;
}

export interface IElementTextContent extends IElementText, IElementVisibility { }

export interface IElementImage
  extends Omit<IBackground, "color">,
  IElementVisibility {
  parallax: boolean; //parallax is use only in BoxBanner
  width?: number;
}

export interface IElementImageInline extends IElementVisibility {
  opacity: number;
  width: ImageInlineWidthOptions;
}
export interface IElementInnerBox extends IElementVisibility {
  color: TColorReadoutValue;
  alignment: IAlignment;
  contentAlignment: AlignmentHorizontalOption;
  width: number;
  textPadding: ISpacing;
}

export interface IElementButton
  extends TButtonReadoutValue,
  IElementVisibility { }

export interface IElementGrid {
  gridPaginationStyle: GridPaginationOptions;
  articlesPerRow: number;
  rowCount: number;
  contentAlignment: AlignmentHorizontalOption;
  spacing: {
    horizontal: number;
    vertical: number;
  };
  isMobile: boolean;
}

export interface IElementSlider {
  articlesCount: number;
  articlesPerSlide: number;
  contentAlignment: AlignmentHorizontalOption;
}

export interface IElementArticleImage extends IElementVisibility {
  hover: HoverEffects;
  alignment: ImagePlacement;
  height: number;
  width: number;
}

export interface ICustomBackgroundPlaceholder {
  backgroundUrl: string;
}

export interface IElementInput extends TInputReadoutValue, IElementVisibility { }

export interface IElementLinkWithVisibility
  extends IElementLink,
  IElementVisibility { }

export interface IElementLink {
  text: TTypoReadoutValue;
  link: TLinkReadoutValue;
}

export interface IElementTooltip {
  font: TTypoReadoutValue;
  background: TColorReadoutValue;
}

export interface IElementVideo {
  autoPlay: boolean;
  showVideoControls: boolean;
  loopVideo: boolean;
  volume: number;
  playbackRate: number;
  muted: boolean;
}

export interface IVideoSize {
  isProcents: boolean;
  procents: number;
  pixels: number;
}
