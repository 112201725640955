import { rest } from "msw";
import { HYDRATE } from "next-redux-wrapper";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { BoxTableDataEndpointType } from "../dto/getDataTable";
import { ATTRIBUTE_LIST_MOCK } from "../mocks/responseAPI/ATTRIBUTES_LIST";
import { ADDRESSES_RESPONSE } from "../mocks/responseAPI/address";
import {
  ARTICLE_CATEGORIES_MOCK,
  ARTICLE_MOCK,
} from "../mocks/responseAPI/articles";
import { ASSOCIATIONS_DATA_MOCK } from "../mocks/responseAPI/associations";
import {
  CART_RESPONSE,
  DELIVERY_AND_PAYMENT,
  MINI_CART_COUNT_RESPONSE,
  MINI_CART_RESPONSE,
  ORDER_STATUS_RESPONSE,
  ORDER_SUMMARY_MOCK,
} from "../mocks/responseAPI/cart";
import {
  ecpCartB2BResponsePut,
  ecpCartB2BResponse_basic,
} from "../mocks/responseAPI/cartB2B/getCartB2B";
import { ecpMiniCartB2BResponse } from "../mocks/responseAPI/cartB2B/getMiniCartB2B";
import { ecpCartSummaryB2BResponse_basic } from "../mocks/responseAPI/cartB2B/getSummaryB2B";
import { CONTRACTORS_DATA_MOCK } from "../mocks/responseAPI/contractors";
import {
  COLUMNS_NAMES_RESPONSE,
  SINGLE_DATA_RESPONSE,
} from "../mocks/responseAPI/customers";
import {
  DELIVERY_SCHEDULE_COLUMNS_NAMES_RESPONSE,
  DELIVERY_SCHEDULE_SINGLE_RESULT_RESPONSE,
} from "../mocks/responseAPI/deliverySchedule";
import {
  LOGISTIC_MIN,
  LOGISTIC_MIN_COLUMNS_NAMES_RESPONSE,
} from "../mocks/responseAPI/logisticMin";
import { MARKETING_BANNERS_MOCK } from "../mocks/responseAPI/marketingBanners";
import { UPLOAD_MEDIA_FILE_RESPONSE } from "../mocks/responseAPI/media";
import { MYACCOUNT_CONSENTS } from "../mocks/responseAPI/myAccount";
import {
  MY_ORDERS_DETAILS_MOCK,
  MY_ORDERS_HISTORY_MOCK,
} from "../mocks/responseAPI/myOrders";
import { PAGES_LIST_MOCK, pageMock } from "../mocks/responseAPI/page";
import {
  PRODUCTS_MOCK,
  PRODUCT_LIST_ATTRIBUTES_MOCK,
  PRODUCT_LIST_ATTRIBUTES_TYPE_MOCK,
  PRODUCT_LIST_CATEGORY_TREE_MOCK,
  PRODUCT_LIST_POOLS_MOCK,
  PRODUCT_LIST_PRODUCT_PROJECTION,
} from "../mocks/responseAPI/productList";
import { STUB_SEARCH } from "../mocks/responseAPI/searchMock";
import { SEARCH_PRODUCTS_V2 } from "../mocks/responseAPI/searchProducts";
import {
  settlementDTO,
  settlementFieldNamesDTO,
} from "../mocks/responseAPI/settlement";
import { STORES_LOCATOR_RESPONSE } from "../mocks/responseAPI/storesLocator";
import { SUGGESTIONS } from "../mocks/responseAPI/suggester";
import { themeSettingsMock } from "../mocks/responseAPI/themeSettings";
import { TITLE_DATA_MOCK } from "../mocks/responseAPI/titleData";
import {
  CREDIT_LIMIT_COLUMNS_NAMES_RESPONSE,
  CREDIT_LIMIT_SINGLE_RESULT_RESPONSE,
} from "./../mocks/responseAPI/creditLimits";
import {
  getSessionStorageTenant,
  isThemeEndpoint,
  isThemeMode,
} from "./tenantHelpers";

export interface ApiQueryErrorResponse
  extends Pick<
    Extract<FetchBaseQueryError, { status: "FETCH_ERROR" }>,
    "status" | "error"
  > {
  data?: {
    errorId: string;
    errors: ApiQueryError[];
    timestamp: string;
  };
}

export interface ApiQueryError {
  code: string;
  message: string;
}

const mockTenant = process.env.NEXT_PUBLIC_TENANT_MOCK ?? "mock";

export const validateErrors = (errors: any) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!errors || !errors?.data)
    throw new Error("Something went wrong. Please try again.");

  const transformedErrors = errors.data.errors;
  return transformedErrors;
};

export const isClientSide = (): boolean => typeof window !== "undefined";

const getEnvironmentPrefix = ():
  | "dev"
  | "uat"
  | "preprod"
  | "prod"
  | undefined => {
  const env = process.env.NEXT_PUBLIC_ENVIRONMENT;
  if (env === "dev") {
    return "dev";
  } else if (env === "uat") {
    return "uat";
  } else if (env === "preprod") {
    return "preprod";
  } else if (env === "prod") {
    return "prod";
  }
  return undefined;
};

const decoratedPageWizardBaseQuery =
  (): BaseQueryFn<string | FetchArgs, any, FetchBaseQueryError> =>
    async (args, baseQueryApi, extraOptions) => {
      const baseUrl = `${window.location.origin}/api`;
      const contractorToken = isClientSide()
        ? (sessionStorage.getItem("contractor-token") ??
          localStorage.getItem("contractor-token"))
        : null;
      const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
          if (process.env.NODE_ENV === "development") {
            headers.set("full-request-url", "localhost");
          }
          if (process.env.NEXT_PUBLIC_TENANT) {
            headers.set("tenant", process.env.NEXT_PUBLIC_TENANT);
          }

          // --- SET TENENT HEADER ---
          const tenant = getSessionStorageTenant();
          tenant && headers.set("tenant", tenant);
          // --- END SET TENENT HEADER ---

          // --- SET CONTRACTOR-TOKEN ---
          if (contractorToken) {
            headers.set("contractor-token", contractorToken);
          }
          // --- END SET CONTRACTOR-TOKEN ---

          // --- SET MOCK TENANT FOR THEME MODE ENDPOINTS WITH CONTEXT ---

          if (isThemeMode() && !isThemeEndpoint(baseQueryApi.endpoint)) {
            headers.set("tenant", mockTenant);
          }
          // --- END SET MOCK TENANT FOR THEME MODE ENDPOINTS WITH CONTEXT ---
          return headers;
        },
      });

      let result = await baseQuery(args, baseQueryApi, extraOptions);

      // --- AUTO AUTH FOR DEVELOPMENT---
      if (
        process.env.NODE_ENV === "development" &&
        result.error &&
        result.error.status === 401 &&
        isClientSide()
      ) {
        const refreshResult = await baseQuery(
          {
            credentials: "include",
            url: API.postLocalhostCMSLogin.endpoint(),
            method: "POST",
            body: {
              login: process.env.NEXT_PUBLIC_CMS_USERNAME,
              password: process.env.NEXT_PUBLIC_CMS_PASSWORD,
            },
          },
          baseQueryApi,
          extraOptions
        );
        document.cookie = `${getEnvironmentPrefix()}_ctoken=${(refreshResult.data as any).jwtToken
          }; path=/`;
        result = await baseQuery(args, baseQueryApi, extraOptions);
      }
      // --- END AUTO AUTH FOR DEVELOPMENT---
      return result;
    };

const getFullRequestUrl = (
  tenantOrgin: string,
  locale: string,
  url: string
): string => {
  const defaultLocale = "pl"; // via next.config
  if (isClientSide()) return window.location.href;
  const localeUrl = locale === defaultLocale ? "" : `/${locale}`;
  return `${tenantOrgin}${localeUrl}${url}`;
};

const decoratedPortalBaseQuery =
  (): BaseQueryFn<string | FetchArgs, any, FetchBaseQueryError> =>
    async (args, baseQueryApi, extraOptions) => {
      const tenantOrigin =
        (baseQueryApi.extra as any).origin ?? window.location.origin; // TODO: should be RootStatePortal
      const baseUrl = `${tenantOrigin}/api`;
      const contractorToken = isClientSide()
        ? (sessionStorage.getItem("contractor-token") ??
          localStorage.getItem("contractor-token"))
        : null;

      const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { extra }: any) => {
          headers.set(
            "full-request-url",
            getFullRequestUrl(tenantOrigin, extra.locale, extra.url)
          );

          if (process.env.NEXT_PUBLIC_TENANT) {
            headers.set("tenant", process.env.NEXT_PUBLIC_TENANT);
          }

          // --- SET TENANT FOR THEME MODE ---
          const themeTenant = extra?.themeTenant ?? getSessionStorageTenant();
          if (themeTenant) {
            headers.set(
              "tenant",
              isThemeEndpoint(baseQueryApi.endpoint) ? themeTenant : mockTenant
            );
          }
          // --- END SET TENANT FOR THEME MODE ---

          // --- SET CONTRACTOR-TOKEN ---
          if (contractorToken) {
            headers.set("contractor-token", contractorToken);
          }
          // --- END SET CONTRACTOR-TOKEN ---

          // --- SET HEADER FOR SSR ---
          if (!isClientSide()) {
            headers.set("Cookie", extra.cookies);
            headers.set("Referer", tenantOrigin);
          }
          // --- END SET HEADER FOR SSR ---

          if (
            ["verifyDomain"].includes(baseQueryApi.endpoint) &&
            !isClientSide()
          ) {
            console.log("VerifyDomain request headers: ", headers);
          }

          return headers;
        },
      });

      const result = await baseQuery(args, baseQueryApi, extraOptions);

      return result;
    };

export const api = createApi({
  baseQuery:
    process.env.NEXT_PUBLIC_APP_NAME === "portal"
      ? decoratedPortalBaseQuery()
      : decoratedPageWizardBaseQuery(),
  tagTypes: [
    "PORTAL_USER_AUTH",
    "SAVED_SETTINGS",
    "DRAFT_PAGE",
    "CART",
    "SEARCH_RESULTS",
    "CONTRACTORS",
    "CART_B2B",
    "MINICART_B2B",
  ],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return (action.payload as any)[reducerPath];
    }
  },
  endpoints: () => ({}),
  keepUnusedDataFor: 1,
});

export const API = {
  getPageWizard: {
    endpoint: (pageId?: string | number) => `/cms/v1/pagewizard/${pageId}`,
    enableMock: false,
    mock: [
      rest.get("/api/cms/v1/pagewizard/1", (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json(pageMock.staticPageForAdvancePublication)
        );
      }),
      rest.get("/api/cms/v1/pagewizard/2", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(pageMock.header));
      }),
      rest.get("/api/cms/v1/pagewizard/3", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(pageMock.footer));
      }),
      rest.get("/api/cms/v1/pagewizard/4", (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json(pageMock.advancePublicationConditions)
        );
      }),
      rest.get("/api/cms/v1/pagewizard/:id", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(pageMock.footer));
      }),
    ],
  },
  getPagePortal: {
    endpoint: (query: string | number) => `/v1/portal/page${query}`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/page", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  putPage: {
    endpoint: (pageId: number) => `/cms/v1/pagewizard/${pageId}`,
    enableMock: false,
    mock: [
      rest.put("/api/cms/v1/pagewizard/:id", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(req.body));
      }),
    ],
  },
  getPageUrlById: {
    endpoint: (id: number | string) => `/v1/portal/page/${id}/url`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/page/:id/url", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postPages: {
    endpoint: () => `/cms/v1/query/pages`,
    enableMock: false,
    mock: [
      rest.post("/api/cms/v1/query/pages", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(PAGES_LIST_MOCK));
      }),
    ],
  },
  getFooters: {
    endpoint: () => `/cms/footers`,
    enableMock: false,
    mock: [
      rest.get("/api/cms/footers", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(PAGES_LIST_MOCK));
      }),
    ],
  },
  getHeaders: {
    endpoint: () => `/cms/headers`,
    enableMock: false,
    mock: [
      rest.get("/api/cms/headers", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(PAGES_LIST_MOCK));
      }),
    ],
  },
  getProducts: {
    endpoint: () => `/products/v1/products/query`,
    enableMock: false,
    mock: [
      rest.post("/api/products/v1/products/query", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(PRODUCTS_MOCK));
      }),
    ],
  },
  getProductListAttributes: {
    endpoint: () => `/products/v1/attributes/query`,
    enableMock: false,
    mock: [
      rest.post("/api/products/v1/attributes/query", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(PRODUCT_LIST_ATTRIBUTES_MOCK));
      }),
    ],
  },
  getProductListAttributesType: {
    endpoint: () => `/products/v1/attribute/type`,
    enableMock: false,
    mock: [
      rest.get("/api/products/v1/attribute/type", (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json(PRODUCT_LIST_ATTRIBUTES_TYPE_MOCK)
        );
      }),
    ],
  },
  getProductProjection: {
    endpoint: () => `/products/v1/srp/product-projection`,
    enableMock: false,
    mock: [
      rest.get("/api/products/v1/srp/product-projection", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(PRODUCT_LIST_PRODUCT_PROJECTION));
      }),
    ],
  },
  getProductListPools: {
    endpoint: () => `/products/v1/groups/query`,
    enableMock: false,
    mock: [
      rest.post("/api/products/v1/groups/query", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(PRODUCT_LIST_POOLS_MOCK));
      }),
    ],
  },
  getProductListCategoryTree: {
    endpoint: () => `/products/v1/category`,
    enableMock: false,
    mock: [
      rest.get("/api/products/v1/category", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(PRODUCT_LIST_CATEGORY_TREE_MOCK));
      }),
    ],
  },
  getArticles: {
    endpoint: () => "/cms/v1/article/query",
    enableMock: false,
    mock: [
      rest.post("/api/cms/v1/article/query", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ARTICLE_MOCK));
      }),
    ],
  },
  getArticleCategories: {
    endpoint: () => "/cms/v1/article/category/query",
    enableMock: false,
    mock: [
      rest.post("/api/cms/v1/article/category/query", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ARTICLE_CATEGORIES_MOCK));
      }),
    ],
  },
  getThemeSettingsPageWizard: {
    endpoint: () => `/cms/v1/portal/settings`,
    enableMock: false,
    mock: [
      rest.get("/api/cms/v1/portal/settings", (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({ content: JSON.stringify(themeSettingsMock) })
        );
      }),
    ],
  },
  getThemeSettingsPortal: {
    endpoint: (type: "MOBILE" | "DESKTOP") =>
      `/portal/v1/portal/settings/${type}`,
    enableMock: false,
    mock: [
      rest.get("/api/cms/v1/portal/settings/*", (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({ content: JSON.stringify(themeSettingsMock) })
        );
      }),
    ],
  },
  postThemeSettings: {
    endpoint: () => `/cms/v1/portal/settings`,
    enableMock: false,
    mock: [
      rest.put("/api/cms/v1/portal/settings", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postRegister: {
    endpoint: () => `/portal/credentials`,
    enableMock: false,
    mock: [
      rest.post("/api/portal/credentials", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  postRegisterConfirmationCode: {
    endpoint: (token: string) => `/portal/credentials/activate/${token}`,
    enableMock: false,
    mock: [
      rest.post("/api/portal/credentials/activate/", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  postRegisterConfirmationCodeRefresh: {
    endpoint: (token: string) => `/portal/credentials/refresh/${token}`,
    enableMock: false,
    mock: [
      rest.post("/api/portal/credentials/refresh/", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  getVerifyResetPasswordToken: {
    endpoint: (token: string) =>
      `/portal/credentials/password-reset-token-check/${token}`,
    enableMock: false,
    mock: [
      rest.get(
        "/api/portal/credentials/password-reset-token-check",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },
  postRemindPassword: {
    endpoint: () => `/portal/credentials/forgotten-password`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/portal/credentials/forgotten-password",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },
  postResetForgettenPassword: {
    endpoint: () => `/portal/credentials/reset-forgotten-password`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/portal/credentials/reset-forgotten-password",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },
  postChangePassword: {
    endpoint: () => `/portal/credentials/reset-password`,
    enableMock: false,
    mock: [
      rest.post("/api/portal/credentials/reset-password", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  postUnsubscribleNewsletter: {
    endpoint: (unsubscribeToken: string) =>
      `/portal/newsletter/${unsubscribeToken}`,
    enableMock: false,
    mock: [
      rest.post("/api/portal/newsletter/:id", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  postNewsletter: {
    endpoint: () => `/portal/newsletter`,
    enableMock: false,
    mock: [
      rest.post("/api/portal/newsletter", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  postContactForm: {
    endpoint: () => `/portal/notifications-sender/contact-form`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/portal/notifications-sender/contact-form",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },

  getSuggestions: {
    endpoint: (phrase: string) => `/products/v1/suggester?phrase=${phrase}`,
    enableMock: false,
    mock: [
      rest.get(`/api/products/v1/suggester`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(SUGGESTIONS));
      }),
    ],
  },

  putMyDataForm: {
    endpoint: () => `/v1/portal/customers/name`,
    enableMock: false,
    mock: [
      rest.put("/api/v1/portal/customers/name", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  getOrderStatus: {
    endpoint: (orderId: string) => `/v1/order/payment-status/${orderId}`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/v1/order/payment-status", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ORDER_STATUS_RESPONSE));
      }),
    ],
  },
  getCartByPortalUserToken: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/cart-by-portal-user-token", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(CART_RESPONSE));
      }),
    ],
  },
  getMiniCartByPortalUserToken: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/minicart`,
    enableMock: false,
    mock: [
      rest.get(
        "/api/v1/portal/cart-by-portal-user-token/minicart",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(MINI_CART_RESPONSE));
        }
      ),
    ],
  },
  getMiniCartCountByPortalUserToken: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/minicart/count`,
    enableMock: false,
    mock: [
      rest.get(
        "/api/v1/portal/cart-by-portal-user-token/minicart/count",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(MINI_CART_COUNT_RESPONSE));
        }
      ),
    ],
  },
  getDeliveryAndPaymentByPortalUserToken: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/delivery-and-payment`,
    enableMock: false,
    mock: [
      rest.get(
        "/api/v1/portal/cart-by-portal-user-token/delivery-and-payment",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(DELIVERY_AND_PAYMENT));
        }
      ),
    ],
  },
  putChangeProductQuantityByPortalUserToken: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/products`,
    enableMock: false,
    mock: [
      rest.put(
        "/api/v1/portal/cart-by-portal-user-token/products",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  postAddProductQuantityByPortalUserToken: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/products`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/v1/portal/cart-by-portal-user-token/products",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  postMergeCart: {
    endpoint: () => `/v1/portal/last-seen/merge`,
    enableMock: false,
    mock: [
      rest.post("/api/v1/portal/last-seen/merge", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  postAssignCart: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/assign`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/v1/portal/cart-by-portal-user-token/assign",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },
  postChangeDeliveryType: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/delivery`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/portal/cart-by-portal-user-token/delivery",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },
  postChangePaymentType: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/payment`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/portal/cart-by-portal-user-token/payment",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },
  postCartAddressByPortalUserToken: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/addresses`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/v1/portal/cart-by-portal-user-token/addresses",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  putCartAddressByPortalUserToken: {
    endpoint: (addressId: number) =>
      `/v1/portal/cart-by-portal-user-token/addresses/${addressId}`,
    enableMock: false,
    mock: [
      rest.put(
        "/api/v1/portal/cart-by-portal-user-token/addresses/:id",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  postChangeCartSelectedAddress: {
    endpoint: (addressId: string) =>
      `/v1/portal/cart-by-portal-user-token/addresses/${addressId}`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/portal/cart-by-portal-user-token/:id/addresses/:id",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  postLoginTestUser: {
    endpoint: () => `/authentications/test-account/authenticate`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/authentications/test-account/authenticate",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  postLogoutTestUser: {
    endpoint: () => `/authentications/test-account/logout`,
    enableMock: false,
    mock: [
      rest.post("/api/authentications/test-account/logout", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postLoginAsCustomer: {
    endpoint: (authenticateToken: string) =>
      `/authentications/authenticate-by-token/${authenticateToken}`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/authentications/authenticate-by-token/:id",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  postGetCustomerGroups: {
    endpoint: () => `/customers/v1/query/grp/**`,
    enableMock: false,
    mock: [
      rest.post("/api/customers/v1/query/grp/**", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postLogin: {
    endpoint: () => `/authentications/authenticate`,
    enableMock: false,
    mock: [
      rest.post("/api/authentications/authenticate", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  deleteLogout: {
    endpoint: () => `/authentications/logout`,
    enableMock: false,
    mock: [
      rest.post("/api/authentications/logout", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  getSearchResults: {
    endpoint: (queries: string) => `/products/v1/search?${queries}`,
    enableMock: false,
    mock: [
      rest.get("/api/products/v1/search/**", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(STUB_SEARCH));
      }),
    ],
  },
  getSearchWithSrpResultsV2: {
    endpoint: (queries: string) => `/products/v2/search/srp?${queries}`,
    enableMock: false,
    mock: [
      rest.post("/api/products/v2/search/srp", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(STUB_SEARCH));
      }),
    ],
  },
  postCartShoppingWithoutRegister: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/owner`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/v1/portal/cart-by-portal-user-token/owner",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },
  getSearchProducts: {
    endpoint: () => `/products/v2/srp`,
    enableMock: false,
    mock: [
      rest.post("/api/products/v2/srp", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(SEARCH_PRODUCTS_V2));
      }),
    ],
  },
  getSearchAttributes: {
    endpoint: () => "/products/v1/attributes",
    enableMock: false,
    mock: [
      rest.get("/api/products/v1/attributes", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ATTRIBUTE_LIST_MOCK));
      }),
    ],
  },
  getFilterableSearchAttributes: {
    endpoint: () => "/products/v1/attributes/filterable",
    enableMock: false,
    mock: [
      rest.get("/api/products/v1/attributes/filterable", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ATTRIBUTE_LIST_MOCK));
      }),
    ],
  },
  getCartOrderConfirmation: {
    endpoint: (orderId: string) =>
      `/v1/portal/cart-by-portal-user-token/cart-order-confirmation?orderId=${orderId}&isErrorPayment=true`, //TODO isErrorPayment will use when connect payment gate
    enableMock: false,
    mock: [
      rest.get(
        "/api/v1/portal/cart-by-portal-user-token/cart-order-confirmation",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(ORDER_SUMMARY_MOCK));
        }
      ),
    ],
  },
  getMyOrdersOrdersList: {
    endpoint: (page: number) => `/v1/portal/orders/history?page=${page}`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/orders/history", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(MY_ORDERS_HISTORY_MOCK));
      }),
    ],
  },
  putMyOrdersOrderCancel: {
    endpoint: (orderUuid: string) => `/v1/orders/${orderUuid}/cancel`,
    enableMock: false,
    mock: [
      rest.put("/api/v1/orders/:id/cancel", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  putMyOrdersDetailsDispatchCancel: {
    endpoint: (orderUuid: string, orderDispatchUuid: string) =>
      `/v1/orders/${orderUuid}/${orderDispatchUuid}/cancel`,
    enableMock: false,
    mock: [
      rest.put("/api/v1/orders/:id/cancel", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postMyOrdersReorderProduct: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/reorder`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/v1/portal/cart-by-portal-user-token/reorder",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  postMyOrdersMergeProduct: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/reorder-merge`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/v1/portal/cart-by-portal-user-token/reorder-merge",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json({}));
        }
      ),
    ],
  },
  getMyOrdersOrderDetails: {
    endpoint: (orderId: string) => `/v1/portal/orders/order/${orderId}`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/orders/order/:id", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(MY_ORDERS_DETAILS_MOCK));
      }),
    ],
  },
  postCartPlaceOrder: {
    endpoint: () => `/v1/portal/cart-by-portal-user-token/place-order`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/v1/portal/cart-by-portal-user-token/place-order",
        (req, res, ctx) => {
          return res(ctx.status(200));
        }
      ),
    ],
  },
  putRepeatOrder: {
    endpoint: (orderId: string) => `/orders/v1/cms/orders/${orderId}/repay`,
    enableMock: false,
    mock: [
      rest.put(`/api/orders/v1/cms/orders/:orderId/repay`, (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  getCustomerData: {
    endpoint: () => `/v1/portal/customer`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/customer", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  getMyAccountConsents: {
    endpoint: () => `/v1/portal/newsletter`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/newsletter", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(MYACCOUNT_CONSENTS));
      }),
    ],
  },
  putMyAccountConsents: {
    endpoint: () => `/v1/portal/newsletter`,
    enableMock: false,
    mock: [
      rest.put("/api/v1/portal/newsletter", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  deleteUserAccount: {
    endpoint: () => `/v1/portal/customers`,
    enableMock: false,
    mock: [
      rest.delete("/api/v1/portal/newsletter", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postAddNewAddress: {
    endpoint: () => `/v1/portal/address`,
    enableMock: false,
    mock: [
      rest.post("/api/v1/portal/address", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  putEditAddress: {
    endpoint: (id: number) => `/v1/portal/address/${id}`,
    enableMock: false,
    mock: [
      rest.put("/api/v1/portal/address", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  getCustomerAddresses: {
    endpoint: () => `/v1/portal/addresses`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/portal/addresses", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ADDRESSES_RESPONSE));
      }),
    ],
  },
  deleteCustomerAddress: {
    endpoint: (addressId: number) => `/v1/portal/address/${addressId}`,
    enableMock: false,
    mock: [
      rest.delete("/api/v1/portal/address/:id", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  putChangeDefaultAddress: {
    endpoint: (addressId: string) => `/v1/portal/address/${addressId}/default`,
    enableMock: false,
    mock: [
      rest.put("/api/v1/portal/address/:id/default", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getMediaParentFolderId: {
    endpoint: () => `/media/folder/parent`,
    enableMock: false,
    mock: [
      rest.get("/api/media/folder/parent", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getFilesWithFolders: {
    endpoint: () => `/media/search`,
    enableMock: false,
    mock: [
      rest.post("/api/media/search", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getVideoFiles: {
    endpoint: () => `/media/search/video`,
    enableMock: false,
    mock: [
      rest.post("/api/media/search/video", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  uploadFile: {
    endpoint: (folderId: string) => `/media/folder/${folderId}/file/upload`,
    enableMock: false,
    mock: [
      rest.post("/api/media/folder/:id/file/upload", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  uploadFileVideo: {
    endpoint: () => `/media/folder/file/upload/video`,
    enableMock: false,
    mock: [
      rest.post("api/media/folder/file/upload/video", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getMediaLibrarySize: {
    endpoint: () => `/media/minio/files/size`,
    enableMock: false,
    mock: [
      rest.post("/api/media/minio/files/size", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postUploadFromURL: {
    endpoint: () => "/media/external-url/file/upload",
    enableMock: false,
    mock: [
      rest.post("/api/media/external-url/file/upload", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(UPLOAD_MEDIA_FILE_RESPONSE));
      }),
    ],
  },
  postAddLastSeen: {
    endpoint: () => "/portal/last-seen/add",
    enableMock: false,
    mock: [
      rest.post("/api/portal/last-seen/add", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getLastSeen: {
    endpoint: () => "/portal/last-seen",
    enableMock: false,
    mock: [
      rest.get("/api/portal/last-seen", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getLastSeenCount: {
    endpoint: () => "/portal/last-seen/count",
    enableMock: false,
    mock: [
      rest.get("/api/portal/last-seen/count", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getWishList: {
    endpoint: () => "/portal/wish-lists",
    enableMock: false,
    mock: [
      rest.get("/api/portal/wish-lists", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postGetWishList: {
    endpoint: () => "/portal/wish-lists",
    enableMock: false,
    mock: [
      rest.post("/api/portal/wish-lists", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postAddProductToWishList: {
    endpoint: () => "/portal/wish-lists/products",
    enableMock: false,
    mock: [
      rest.post("/api/portal/wish-lists/products", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  deleteProductFromWishList: {
    endpoint: () => "/portal/wish-lists/products",
    enableMock: false,
    mock: [
      rest.delete("/api/portal/wish-lists/products", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getBreadcrumbs: {
    endpoint: (url: string) => `/breadcrumbs${url}`,
    enableMock: false,
    mock: [
      rest.get("/api/breadcrumbs", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getStoresLocator: {
    endpoint: () => `/portal/delivery-channels/stores/locator`,
    enableMock: false,
    mock: [
      rest.post(
        "/api/portal/delivery-channels/stores/locator",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(STORES_LOCATOR_RESPONSE));
        }
      ),
    ],
  },
  getTenantDomains: {
    endpoint: () => `/v1/tenant/domains`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/tenant/domains", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  verifyDomain: {
    endpoint: (domain: string) => `/v1/tenant/domain/${domain}/exist`,
    enableMock: false,
    mock: [
      rest.get("/api/v1/tenant/domain/:id/exist", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postSaveLocalBoxAsGlobal: {
    endpoint: () => `/cms/boxes`,
    enableMock: false,
    mock: [
      rest.post("api/cms/boxes", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  putSaveGlobalBoxChanges: {
    endpoint: (id: number) => `/cms/boxes/${id}`,
    enableMock: false,
    mock: [
      rest.put("api/cms/boxes/:id", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postGlobalBoxes: {
    endpoint: () => `/cms/v1/query/boxes`,
    enableMock: false,
    mock: [
      rest.post("api/cms/v1/query/boxes", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getGlobalBox: {
    endpoint: (id: number) => `/cms/boxes/${id}`,
    enableMock: false,
    mock: [
      rest.get("api/cms/boxes/:id", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  copyGlobalBox: {
    endpoint: (id: number) => `/cms/boxes/${id}/copy`,
    enableMock: false,
    mock: [
      rest.post("api/cms/boxes/:id/copy", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getBoxesType: {
    endpoint: () => `/cms/boxes/global/types`,
    enableMock: false,
    mock: [
      rest.get("api/cms/boxes/global/types", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  postLocalhostCMSLogin: {
    //Only for development
    endpoint: () => `/v1/authentications/login/frontend`,
    enableMock: false,
    mock: [
      rest.post("/api/v1/authentications/login/frontend", (req, res, ctx) => {
        return res(ctx.status(200));
      }),
    ],
  },
  getTitleData: {
    endpoint: (tenant: string) => `/v1/store/basic-data/${tenant}`,
    enableMock: false,
    mock: [
      rest.get(`/api/v1/store/basic-data/:storeSlugName`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(TITLE_DATA_MOCK));
      }),
    ],
  },
  postCustomersColumnNames: {
    endpoint: (endpointType: string) =>
      `/customers/v1/query/columnNames/${endpointType}`,
    enableMock: false,
    mock: [
      rest.post(
        "/customers/v1/query/columnNames/:dataType",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(COLUMNS_NAMES_RESPONSE));
        }
      ),
    ],
  },
  postCustomersSingleResult: {
    endpoint: (endpointType: string) =>
      `/customers/v1/query/singleResult/${endpointType}`,
    enableMock: false,
    mock: [
      rest.post(
        "/customers/v1/query/singleResult/:dataType",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json([SINGLE_DATA_RESPONSE]));
        }
      ),
    ],
  },
  postPayerSingleResult: {
    endpoint: (endpointType: string) =>
      `/payers/v1/query/singleResult/${endpointType}`,
    enableMock: false,
    mock: [
      rest.post("/payers/v1/query/singleResult/:dataType", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json([]));
      }),
    ],
  },
  getAssociations: {
    endpoint: () => `/products/v1/association`,
    enableMock: false,
    mock: [
      rest.get(`/api/products/v1/association`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ASSOCIATIONS_DATA_MOCK));
      }),
    ],
  },
  getContractors: {
    endpoint: () => `/contractors`,
    enableMock: false,
    mock: [
      rest.get(`/api/contractors`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(CONTRACTORS_DATA_MOCK));
      }),
    ],
  },
  putContractors: {
    endpoint: () => `/contractors`,
    enableMock: false,
    mock: [
      rest.put(`/api/contractors`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getLogisticMinColumnNames: {
    endpoint: () => `/v1/logistic/min/columnNames`,
    enableMock: false,
    mock: [
      rest.get(`/api/v1/logistic/min/columnNames`, (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json(LOGISTIC_MIN_COLUMNS_NAMES_RESPONSE)
        );
      }),
    ],
  },
  getCreditLimitsFieldNames: {
    endpoint: () => `/contractor/credit-limit/fieldNames`,
    enableMock: false,
    mock: [
      rest.get(`/contractor/credit-limit/fieldNames`, (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json(CREDIT_LIMIT_COLUMNS_NAMES_RESPONSE)
        );
      }),
    ],
  },
  postLogisticMinSingleResult: {
    endpoint: () => `/v1/logistic/min/singleResult`,
    enableMock: false,
    mock: [
      rest.post("/api/v1/logistic/min/singleResult", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json([LOGISTIC_MIN]));
      }),
    ],
  },
  postCreditLimitsSingleResult: {
    endpoint: () => `/contractor/credit-limit/query`,
    enableMock: false,
    mock: [
      rest.post("/contractor/credit-limit/query", (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json([CREDIT_LIMIT_SINGLE_RESULT_RESPONSE])
        );
      }),
    ],
  },
  getDeliveryScheduleColumnNames: {
    endpoint: () => `/get/data/columns/delivery`,
    enableMock: false,
    mock: [
      rest.get(`/api/get/data/columns/delivery`, (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json(DELIVERY_SCHEDULE_COLUMNS_NAMES_RESPONSE)
        );
      }),
    ],
  },
  postDeliveryScheduleSingleResult: {
    endpoint: () => `/get/data/single/delivery`,
    enableMock: false,
    mock: [
      rest.post("/api/get/data/single/delivery", (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json([DELIVERY_SCHEDULE_SINGLE_RESULT_RESPONSE])
        );
      }),
    ],
  },
  postSettlement: {
    endpoint: () => `/payments/settlement`,
    enableMock: false,
    mock: [
      rest.post("/api/payments/settlement", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(settlementDTO));
      }),
    ],
  },
  getSettlementFieldNames: {
    endpoint: () => `/payments/settlement/fieldNames`,
    enableMock: false,
    mock: [
      rest.get("/api/payments/settlement/fieldNames", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(settlementFieldNamesDTO));
      }),
    ],
  },
  getGetDataColumnNames: {
    endpoint: (endpoint: BoxTableDataEndpointType) =>
      `/portal/get-data/column-names/${endpoint}`,
    enableMock: false,
    mock: [
      rest.get(`/api/portal/get-data/column-names/*`, (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({
            columnNames: ["imie", "nazwisko"],
          })
        );
      }),
    ],
  },
  getTableData: {
    endpoint: () => `/portal/get-data`,
    enableMock: false,
    mock: [
      rest.post(`/api/portal/get-data`, (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({
            result: [
              { imie: "Jan", nazwisko: "Kowalski" },
              { imie: "Andrzej", nazwisko: "Nowak" },
              { imie: "Piotr", nazwisko: "Kowalski" },
              { imie: "Jan", nazwisko: "Nowak" },
            ],
            page: {
              number: 1,
              size: 4,
              total: 4,
              count: 1,
            },
          })
        );
      }),
    ],
  },
  getProductPageConfigs: {
    endpoint: () => `/cms/v1/pages/product-page-configs`,
    enableMock: false,
    mock: [
      rest.get("/api/cms/v1/pages/product-page-configs", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
      }),
    ],
  },
  getCartB2B: {
    endpoint: () => `/portal/contractor/cart`,
    enableMock: false,
    mock: [
      rest.get(`/api/portal/contractor/cart`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ecpCartB2BResponse_basic));
      }),
    ],
  },
  putAddToCartB2B: {
    endpoint: () => `/portal/contractor/add-to-cart`,
    enableMock: false,
    mock: [
      rest.put("/api/portal/contractor/add-to-cart", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ecpCartB2BResponsePut));
      }),
    ],
  },
  deleteProductCartB2B: {
    endpoint: (query: { sku: string; productType: string }) =>
      `/portal/contractor/clear-cart/product?sku=${query.sku}&productType=${query.productType}`,
    enableMock: false,
    mock: [
      rest.delete(
        "/api/portal/contractor/clear-cart/product",
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(ecpCartB2BResponsePut));
        }
      ),
    ],
  },
  putSetDeliveryDateCartB2B: {
    endpoint: () => `/portal/contractor/delivery-date`,
    enableMock: false,
    mock: [
      rest.put("/api/portal/contractor/delivery-date", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ecpCartB2BResponsePut));
      }),
    ],
  },
  putClearProductTypeB2B: {
    endpoint: (productType: string) =>
      `/portal/contractor/clear-cart/order?productType=${productType}`,
    enableMock: false,
    mock: [
      rest.put("/api/portal/contractor/clear-cart/order", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ecpCartB2BResponsePut));
      }),
    ],
  },
  putClearCartB2B: {
    endpoint: () => `/portal/contractor/clear-cart`,
    enableMock: false,
    mock: [
      rest.put("/api/portal/contractor/clear-cart", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ecpCartB2BResponsePut));
      }),
    ],
  },
  putSetPaymentMethodCartB2B: {
    endpoint: () => `/portal/contractor/payment`,
    enableMock: false,
    mock: [
      rest.put("/api/portal/contractor/payment", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ecpCartB2BResponsePut));
      }),
    ],
  },
  putPlaceOrderCartB2B: {
    endpoint: () => `/portal/contractor/place-order`,
    enableMock: false,
    mock: [
      rest.put("/api/portal/contractor/place-order", (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({ cart: ecpCartB2BResponsePut, cartId: "123" })
        );
      }),
    ],
  },
  getMarketingBanners: {
    endpoint: (categoryCode: string) =>
      `/portal/marketing-campaign/category/${categoryCode}/banners`,
    enableMock: false,
    mock: [
      rest.get(
        `/api/portal/cart/marketing-campaign/category/:categoryCode/banners`,
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(MARKETING_BANNERS_MOCK));
        }
      ),
    ],
  },
  getSummaryB2B: {
    endpoint: (cartId: string) => `/contractors/orders/${cartId}`,
    enableMock: false,
    mock: [
      rest.get(`/api/contractors/orders/:cartId`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ecpCartSummaryB2BResponse_basic));
      }),
    ],
  },
  getMiniCartB2B: {
    endpoint: () => `/portal/contractor/mini-cart`,
    enableMock: false,
    mock: [
      rest.get(`/api/portal/contractor/mini-cart`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(ecpMiniCartB2BResponse));
      }),
    ],
  },
};
