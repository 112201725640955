export enum FacetParam {
  TREE = "TREE",
  FACET = "FACET",
}

interface FacetValueDto {
  name: string;
  count: number;
}

export interface CategoryValueDto {
  name: string;
  count: number;
  id: string;
  parentId: string;
}

export interface FacetCatDto<T extends FacetParam> {
  name: string;
  type: T;
  values: T extends FacetParam.FACET ? FacetValueDto[] : CategoryValueDto[];
  min?: string;
  max?: string;
}

export const isCategoryValue = (
  param: FacetCatDto<FacetParam>
): param is FacetCatDto<FacetParam.TREE> => param.type === FacetParam.TREE;

export interface CategoryDto {
  name: string; // "cat02" "cat03" .. "0202"
  type: FacetParam.TREE;
  values: {
    name: string;
    count: number;
    id: string;
    parentId: string; // "cat02" "cat03" .. "0202"
  };
}

export type TAttributeValue = {
  value: string;
  label: string | null;
};

interface DebugFields {
  name: string;
  index: string;
  score: string;
}

export interface ISrpRequestV2 {
  skus: string[];
  projection: (keyof ISrpTransformResponseV2)[];
  associations?: string[];
}

export interface ISrpResponseV2 {
  products: ISrpTransformResponseV2[];
  size: number;
}

export interface ISrpWithSearchTransformResponseV2 {
  products: ISrpTransformResponseV2[];
  size: number;
  attributesLineDetection?: Record<
    keyof Partial<ISrpTransformResponseV2>,
    boolean
  >;
}

export interface ISearchResultsRequest {
  query: string;
  strictCategory?: string; // is inside params
}

export interface ISearchResultsResponse {
  size: number;
  products: {
    sku: string;
    debugFields?: DebugFields; // &mode=debug
  }[];
  parameters: FacetCatDto<FacetParam>[];
}

export type TSkusResponse = Omit<ISearchResultsResponse, "parameters">;

export interface ISearchResultsTransformResponse {
  size: number;
  products: string[];
  parameters: FacetCatDto<FacetParam>[];
}

export interface Pool {
  id: number;
  name: string;
}

export enum TSrpProductType {
  PRODUCT = "PRODUCT",
  VARIANT = "VARIANT",
  CONCEPT = "CONCEPT",
}

export type SrpFields = {
  rating: {
    numberOfReviews: number;
    rate: number | null;
  };
  badges: BadgeDto[];
  pools: Pool[];
  deliveryTime: DeliveryTimeCode;
  sku: string;
  conceptId: string | null;
  url: string;
  mainUnitOfMeasurement: string;
  mainUnitOfMeasurementLabel: string;
  alternativeUnitOfMeasures: {
    value: number;
    auoM: string;
    auoMLabel: string;
  }[];
  deliveryQuantity: number;
  concession: boolean;
  wishlist: {
    base: boolean;
    concept: boolean;
  };
  name: string;
  hoverPhoto: string;
  coverPhoto: string;
  photoGallery: string[];
  variants: VariantDto[];
  basePrice: number;
  promotionPrice: number;
  suggestedPrice: number;
  netPrice: number;
  grossPrice: number;
  omnibusPrice: number | null; // omnibusPrice is null when promotionPrice is 0
  actualPrice: number;
  oldPrice: number | null; // oldPrice is null when promotionPrice is 0
  type: TSrpProductType;
  axis: string;
  associations: Record<string, string[]>;
  lowestCategoryLevel: string;
  tax: number;
};

export type SrpKeys = keyof SrpFields;

type ExtraFields = Record<
  Exclude<string, SrpKeys>,
  string | number | boolean | null | TAttributeValue | TAttributeValue[] | any // any because https://stackoverflow.com/questions/61431397/how-to-define-typescript-type-as-a-dictionary-of-strings-but-with-one-numeric-i
>;

export type ISrpTransformResponseV2 = SrpFields & ExtraFields;

export type VariantDto = {
  sku: string;
  attribute: {
    value: string;
    label: string;
  };
  order: number;
  inStock: boolean;
  deliveryTime: DeliveryTimeCode | null;
  enabled: boolean;
  productUrl: string;
};

export interface BadgeDto {
  id: number;
  name: string;
  badgeKind: BadgeType;
}

export enum BadgeType {
  PROMOTION = "PROMOTION",
  BESTSELLER = "BESTSELLER",
  NEW = "NEW",
}

export enum DeliveryTimeCode {
  HOURS_24 = "HOURS_24",
  HOURS_48 = "HOURS_48",
  HOURS_72 = "HOURS_72",
  DAYS_4_5 = "DAYS_4_5",
  DAYS_6_8 = "DAYS_6_8",
  WEEKS_2 = "WEEKS_2",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum SortingItemType {
  DATE_ADDED = "DATE_ADDED",
  DATE_ADDED_DESC = "DATE_ADDED_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  RATING_ASC = "RATING_ASC",
  RATING_DESC = "RATING_DESC",
  POPULARITY = "POPULARITY",
  RANDOM = "RANDOM",
  BY_ATTRIBUTE = "BY_ATTRIBUTE",
}

export enum SortingItemQuery {
  DATE_ADDED = "created_at",
  DATE_ADDED_DESC = "-created_at",
  NAME_ASC = "name",
  NAME_DESC = "-name",
  PRICE_ASC = "price",
  PRICE_DESC = "-price",
  RATING_ASC = "rating",
  RATING_DESC = "-rating",
  POPULARITY = "score",
  RANDOM = "random",
  //Sorting by attribute is not specified because it is variable depending on what we get from the backend for a given attribute
  //The operation of sorting by attribute is done in a function called getSortQueryBySortType()
  BY_ATTRIBUTE = "",
}

export enum SortingItemVariant {
  ASC = "ASC",
  DESC = "DESC",
}
