import {
  ILinkContent,
  SelectedGoToOption,
} from "../../settingsPatterns/contentPatterns.types";
import { PortalPath } from "../../shared/portalPath/portalPath";
import { EffectsData } from "../../structure/Contexts/SectionEffectsContext";

const OFFSET_CORRECTION = 1

export const checkIfButtonShouldBeDisabled = (
  isBoxSelected: boolean,
  link: ILinkContent,
  href: string | undefined
) => {

  if (link.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE) {
    return !href;
  }

  if (link.selectedGoToOption === SelectedGoToOption.GO_TO_ROW) {
    return !link.goToRowSetting.rowId;
  }

  if (link.selectedGoToOption === SelectedGoToOption.OPEN_POPUP) {
    return !link.openPopupSetting.rowId;
  }

  if (link.selectedGoToOption === SelectedGoToOption.CLOSE_POPUP) {
    return !link.closePopupSetting.rowId;
  }

  if (link.selectedGoToOption === SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK) {
    return !isBoxSelected;
  }
  return false;
};


export const scrollIntoRowPosition = (
  link: ILinkContent,
  path: PortalPath,
  effectsData: EffectsData[]
) => {
  if (link.selectedGoToOption !== SelectedGoToOption.GO_TO_ROW) return;

  const getScrollTargetOffset = () => {
    const buttonIdx = effectsData.findIndex(
      (el) => el.sectionId === path.getFirstSection()
    );
    const buttonOffset =
      (effectsData[buttonIdx]?.height ?? 0) +
      (effectsData[buttonIdx]?.offset ?? 0);

    const scrollTargetIdx = effectsData.findIndex(
      (el) => el.sectionId?.toString() === link.goToRowSetting.rowId
    );
    const topTargetSection =
      (effectsData[scrollTargetIdx]?.sumHeight ?? 0) -
      (effectsData[scrollTargetIdx]?.height ?? 0);

    //obliczanie offsetu przesunięcia dla sticky elementów między sekcją buttona a sekcją target
    const spaceBetweenButtonAndTarget = [...effectsData]
      .splice(buttonIdx + 1, scrollTargetIdx - buttonIdx)
      .filter((el) => el.sticky)
      .reduce((acc, el) => acc + (el.height ?? 0), 0);

    //obliczanie offsetu przesunięcia w przypadku gdy sekcja buttona nie jest sticky
    const spaceBetweenTopPageAndButton = [...effectsData]
      .splice(0, buttonIdx - 1)
      .filter((el) => el.sticky)
      .reduce((acc, el) => acc + (el.height ?? 0), 0);

    console.log(buttonOffset, topTargetSection, spaceBetweenTopPageAndButton);

    if (effectsData[buttonIdx].sticky) {
      return topTargetSection - buttonOffset - spaceBetweenButtonAndTarget + OFFSET_CORRECTION;
    } else {
      return (
        topTargetSection -
        spaceBetweenButtonAndTarget -
        spaceBetweenTopPageAndButton + OFFSET_CORRECTION
      );
    }
  };

  document?.getElementById("scroll-wrapper")?.scrollTo({
    top: getScrollTargetOffset(),
  });
};
